export default [
  "afar",
  "ahem",
  "ahoy",
  "alas",
  "anti",
  "area",
  "atop",
  "away",
  "baby",
  "back",
  "ball",
  "band",
  "bang",
  "bank",
  "bare",
  "base",
  "bear",
  "beat",
  "bill",
  "blue",
  "body",
  "bold",
  "book",
  "both",
  "burn",
  "busy",
  "call",
  "calm",
  "card",
  "care",
  "case",
  "cash",
  "cast",
  "city",
  "club",
  "cold",
  "come",
  "cook",
  "cool",
  "cope",
  "cost",
  "dare",
  "dark",
  "darn",
  "date",
  "deal",
  "dear",
  "deep",
  "doom",
  "door",
  "down",
  "draw",
  "drop",
  "dual",
  "dull",
  "duty",
  "earn",
  "east",
  "easy",
  "edge",
  "egad",
  "else",
  "ergo",
  "even",
  "ever",
  "evil",
  "face",
  "fact",
  "fail",
  "fair",
  "fall",
  "farm",
  "fast",
  "fear",
  "feel",
  "file",
  "fill",
  "film",
  "find",
  "fine",
  "fire",
  "firm",
  "fish",
  "flat",
  "flip",
  "fond",
  "food",
  "foot",
  "fore",
  "form",
  "foul",
  "four",
  "free",
  "from",
  "full",
  "fund",
  "gain",
  "game",
  "girl",
  "give",
  "glad",
  "goal",
  "gold",
  "good",
  "gosh",
  "grey",
  "grim",
  "grow",
  "hair",
  "half",
  "hall",
  "hand",
  "hang",
  "hard",
  "hate",
  "have",
  "head",
  "hear",
  "heck",
  "help",
  "here",
  "hers",
  "hide",
  "high",
  "hill",
  "hold",
  "home",
  "honk",
  "hope",
  "hour",
  "huge",
  "hurt",
  "idea",
  "idly",
  "into",
  "join",
  "jump",
  "just",
  "keen",
  "keep",
  "kill",
  "kind",
  "king",
  "know",
  "lack",
  "lady",
  "land",
  "last",
  "late",
  "lazy",
  "lead",
  "lend",
  "lest",
  "life",
  "lift",
  "like",
  "line",
  "link",
  "list",
  "live",
  "lone",
  "long",
  "look",
  "lord",
  "lose",
  "loss",
  "loud",
  "main",
  "make",
  "many",
  "mark",
  "mass",
  "mean",
  "meet",
  "meow",
  "mild",
  "mind",
  "mine",
  "miss",
  "move",
  "much",
  "must",
  "nada",
  "name",
  "near",
  "neat",
  "need",
  "news",
  "next",
  "nice",
  "none",
  "note",
  "okay",
  "once",
  "only",
  "onto",
  "oops",
  "open",
  "ouch",
  "ours",
  "over",
  "page",
  "pair",
  "pale",
  "park",
  "part",
  "pass",
  "past",
  "path",
  "phew",
  "pick",
  "pink",
  "plan",
  "play",
  "plus",
  "poof",
  "poor",
  "post",
  "pull",
  "pure",
  "push",
  "race",
  "rain",
  "rare",
  "rate",
  "read",
  "real",
  "rear",
  "rely",
  "rest",
  "rich",
  "ride",
  "ring",
  "rise",
  "risk",
  "road",
  "rock",
  "role",
  "roll",
  "room",
  "rude",
  "rule",
  "safe",
  "sale",
  "same",
  "save",
  "seat",
  "seek",
  "seem",
  "self",
  "sell",
  "send",
  "shed",
  "shop",
  "show",
  "shut",
  "sick",
  "side",
  "sign",
  "sing",
  "site",
  "size",
  "slim",
  "slip",
  "slow",
  "snap",
  "soft",
  "sole",
  "solo",
  "some",
  "soon",
  "sort",
  "star",
  "stay",
  "step",
  "stop",
  "such",
  "suit",
  "sure",
  "take",
  "talk",
  "tall",
  "task",
  "team",
  "tell",
  "tend",
  "term",
  "test",
  "text",
  "than",
  "that",
  "thee",
  "them",
  "then",
  "they",
  "thin",
  "this",
  "thor",
  "thus",
  "tidy",
  "till",
  "time",
  "tiny",
  "tone",
  "tour",
  "town",
  "tree",
  "true",
  "turn",
  "type",
  "ugly",
  "unit",
  "unto",
  "upon",
  "user",
  "vain",
  "vast",
  "very",
  "vice",
  "view",
  "vote",
  "wait",
  "wake",
  "walk",
  "wall",
  "want",
  "warm",
  "warn",
  "wary",
  "wash",
  "wear",
  "week",
  "west",
  "wham",
  "what",
  "when",
  "whiz",
  "wide",
  "wild",
  "will",
  "wind",
  "wine",
  "wise",
  "wish",
  "with",
  "wood",
  "word",
  "work",
  "year",
  "your",
  "yuck",
  "zero",
];
